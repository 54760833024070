import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'

import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import cloudsecurity from '../images/services/new/pragicts-cloudops-security.jpeg'
import infrastructure from '../images/services/new/pragicts-cloudops-infrastructure.jpg'
import iot from '../images/services/new/pragicts-cloudops-iot.jpg'
import saas from '../images/services/new/pragicts-cloudops-sase.jpg'
import consulting from '../images/services/new/consulting.jpg'

const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | CloudOps | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={cloudsecurity}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Cloud Security</h3>
                                                <p>Cloud security, also referred to as cloud computing security, encompasses a range of security measures aimed at safeguarding cloud-based infrastructure, applications, and data. In today's dynamic landscape, organizations often adopt a multi-vendor cloud strategy to leverage the best cloud technology services and platforms for their business requirements. However, managing security across multiple cloud environments necessitates a cohesive and comprehensive approach to ensure consistent protection.</p>
                                                <p>PragICTS offers cloud security services tailored to effectively manage security across the entire IT landscape. Leveraging our expertise, organizations can develop and implement robust cloud security strategies to mitigate risks and safeguard their cloud-based assets. From threat detection and incident response to compliance management and data protection, we provide holistic security solutions to meet the evolving needs of our clients in the cloud era.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={infrastructure}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Cloud Infrastructure Management </h3>
                                                <p>Cloud management involves the systematic supervision, control, administration, and upkeep of public cloud, private cloud, or hybrid multi-cloud computing infrastructure, services, and resources. It empowers IT teams with the tools and capabilities needed to effectively manage scalable and dynamic cloud computing environments, ensuring optimal performance and resource utilization. This encompasses end-to-end activities such as design, provisioning, management, and support of cloud infrastructure.</p>
                                                <p>PragICTS specializes in providing comprehensive cloud management services, including security, to ensure the effective oversight and governance of cloud environments across the entire IT landscape. Leveraging our expertise, organizations can confidently navigate the complexities of cloud computing, optimize their cloud resources, and drive business growth through efficient and secure cloud operations.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={iot}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Internet of Things (IoT)</h3>
                                                <p>The Internet of Things (IoT) refers to devices equipped with sensors, processing capabilities, software, and other technologies that can connect and exchange data with other devices and systems over the Internet or other communication networks. IoT spans across various fields including electronics, communication, and computer science engineering. Despite its name, IoT devices do not necessarily require a connection to the public internet; they simply need to be connected to a network and be individually addressable.</p>
                                                <p>PragICTS offers a range of IoT-related services to empower clients to collect and utilize real-time data and information from previously inaccessible sources. These services enable organizations to harness the power of IoT for enhanced decision-making and operational efficiency. Complementing these offerings is the Sentinel platform <a class="codered" href="https://sentinel.pragicts.com" target="_blank">(https://sentinel.pragicts.com)</a>, which provides centralized monitoring of the entire business landscape, offering real-time insights and control from any location.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={consulting}>
                                    
                                    <a href='https://automation.pragicts.com' target='_black'>  <div className="hidden-works-item-text">
                                                <h3>IT Automation</h3>
                                                <p>IT automation involves developing software and systems to automate repetitive processes and minimize manual intervention. By replacing manual tasks with automated workflows, IT automation streamlines the delivery of IT infrastructure and applications, including those hosted in the cloud. This automation eliminates the need for human involvement in tasks that were previously performed manually, leading to a reduction in errors and an increase in throughput. Overall, IT automation enables organizations to expedite the deployment and management of IT resources, ultimately improving efficiency and productivity.
</p>
                                                <p>PragICTS offers comprehensive services aimed at designing, implementing, managing, and supporting automation solutions across the entire IT landscape. Leveraging our expertise, organizations can streamline processes, enhance operational efficiency, and accelerate the delivery of IT infrastructure and applications. Whether it's automating routine tasks, deploying cloud resources, or optimizing workflows, PragICTS provides tailored automation solutions to meet the specific needs and objectives of our clients. With our support, organizations can unlock the full potential of automation to drive innovation and business growth. For fuller details refer to <a class="codered" href="https://automation.pragicts.com" target="_blank">(https://automation.pragicts.com)</a></p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                        
                                            </div></a>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={saas}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>SASE (Secure Assess Service Edge) and SSE (Secure Service Edge)</h3>
                                                <p>Secure Access Service Edge (SASE) and Secure Service Edge (SSE) represent cloud-centric and native approaches to networking, security, and performance optimization. PragICTS offers a comprehensive suite of services covering the design, implementation, transformation, management, and support for SASE and SSE solutions. At a micro level, our services include:</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                                <span className="serv-number">05.</span>
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Secure Connectivity</h3>
                                                <p>SASE/SSE integrates secure access technologies such as VPN, SD-WAN (Software-Defined Wide Area Network), and zero-trust network access (ZTNA) to provide secure and optimized network connections for users, devices, and branch offices, regardless of their location.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>


                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Network Security</h3>
                                                <p>SASE/SSE combines multiple security functions, including firewall-as-a-service (FWaaS), secure web gateways (SWG), data loss prevention (DLP), and more into a unified security platform. This consolidates security capabilities and simplifies management.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Identity-Centric Security</h3>
                                                <p>Access and security policies in SASE/SSE are often based on user and device identities, implementing zero-trust principles. Identity and access management (IAM) play a crucial role in controlling access.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Zero Trust</h3>
                                                <p>SASE/SSE embraces the Zero Trust model, where trust is never assumed, and access decisions are made based on continuous verification and contextual factors.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Content and Threat Inspection</h3>
                                                <p>SASE/SSE services include content filtering and threat detection capabilities to protect against malware, phishing, and other cyber threats.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Simplified Management</h3>
                                                <p>Centralized management consoles provide administrators with a holistic view of network security and connectivity. This reduces complexity and enhances control.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>User Experience Optimization</h3>
                                                <p>SASE/SSE services may include features for optimizing the performance and user experience of cloud applications by using techniques like direct-to-cloud routing.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Global Reach</h3>
                                                <p>SASE/SSE providers often have a distributed network of points of presence (PoPs) around the world, allowing organizations to provide secure, low-latency access to cloud resources and branch offices globally.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
